export const yobRegexString = '^\\d{4}$';
export const yobRegex = new RegExp(yobRegexString);
export const currencyRegexString = '^[1-9]\\d{0,2}(?:,\\d{3})*(?:\\.\\d+)?$';
export const currencyRegex = new RegExp(currencyRegexString);
export const nameRegexString = '^[a-zA-Z]+(?:-[a-zA-Z]+)*$';
export const nameRegex = new RegExp(nameRegexString);
export const firstAndLastNameRegex = new RegExp('^[a-zA-Z]+(?:-[a-zA-Z]+)*\\s[a-zA-Z]+(?:-[a-zA-Z]+)*$');
export const emailRegexString = '^(\\D)+(\\w)*((\\.(\\w)+)?)+@(\\D)+(\\w)*((\\.(\\D)+(\\w)*)+)?(\\.)[a-z]{2,}$';
export const dateRegexString = '^(?:0?[1-9]|[12][0-9]|3[01])/(?:0?[1-9]|1[0-2])/\\d{4}$';
export const phoneRegexString = '^(?:\\+234|0)\\d{10}$';
export const phoneRegex = new RegExp(phoneRegexString);
export const yearRegex = /^\d{4}$/
// export const currencyInputCheckerRegex = /^[1-9][0-1]*(?:\.\d*)?$/;
export const currencyInputCheckerRegex = /^[1-9][0-9]*(?:\.\d*)?$/;